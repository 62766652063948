import React from 'react';
import { navigate } from 'gatsby';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const AgeVerification = () => {
  const handleOver18 = () => {
    // Set an item in localStorage to remember the user's age verification
    localStorage.setItem('ageVerified', 'true');
    navigate('/'); // Navigate to the index page
  };

  const handleNot18Yet = () => {
    window.location.href = 'https://www.youtube.com/watch?v=9rxTBrpnRZc'; // Redirect to Google
  };

  return (
    <section className="ageverify">
      <h1>Age Verification</h1>
      <p>This website contains age-restricted materials including nudity and explicit depictions of sexual activity. By entering, you affirm that you are at least 18 years of age or the age of majority in the jurisdiction you are accessing the website from and you consent to viewing sexually explicit content.</p>
        <Button variant="contained" color="primary"
          size="large"
          onClick={handleNot18Yet}
          >
          I am Under 18 - Exit
        </Button>
        <Button variant="contained" color="primary"
          size="large"
          onClick={handleOver18}
          >
          I am Over 18 - Enter
        </Button>
    </section>
  );
};

export default AgeVerification;
